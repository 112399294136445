<template>
  <div :uk-slider="`autoplay: true; autoplay-interval: ${autoPlayTime}`"
       class="anz-main-page-slider uk-position-relative uk-visible-toggle uk-light uk-slider-container-offset dashboard-banner-slider" tabindex="-1">
    <ul id="dashboard" class="uk-slider-items uk-grid" >
      <li v-for="(item, index) in advertisements" :key="'ad_slider_list_item_' + index"
          class="uk-width-5-5">
        <div class="uk-panel" uk-grid>

          <img v-if="item.image_link" :src="item.image_link" alt="" height="auto" width="100%">

          <div class="uk-position-center-left uk-text-left" style="padding-top: 9vw;padding-left: 4vw;">
            <h2 v-if="item.name">{{ item.name }}</h2>
            <p v-if="item.text">{{ item.text }}</p>
            <a v-if="item.url || item.button_text" :href="item.url" :target="item.is_new_tab ? '_blank' : ''"
               class="btn btn-default" style="background-color:  rgba(0, 51,102);">
              {{ item.button_text }}
            </a>
          </div>
        </div>
      </li>
    </ul>
    <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous
       uk-slider-item="previous"></a>
    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next
       uk-slider-item="next"></a>
  </div>
</template>

<script>
export default {
  name: "anz-main-page-slider",
  props: {
    advertisements: {
      type: Array,
      default: () => []
    },
    autoPlayTime: {
      type: Number,
      default: 4000
    }
  },
}
</script>
